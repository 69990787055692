import React from "react";

import {SvgIcon, SvgIconProps} from "@mui/material";

export default function TapbackDislikeIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path d="M20,15L22,15C22.5523,15 23,14.5523 23,14L23,4C23,3.4477 22.5523,3 22,3L20,3C19.4477,3 19,3.4477 19,4L19,14C19,14.5523 19.4477,15 20,15ZM15,3L6,3C5.17,3 4.46,3.5 4.16,4.22L1.14,11.27C1.05,11.5 1,11.74 1,12L1,14C1,15.1046 1.8954,16 3,16L9.31,16L8.36,20.57C8.34,20.67 8.33,20.77 8.33,20.88C8.33,21.3 8.5,21.67 8.77,21.94L9.1224,22.2934C9.5129,22.684 10.146,22.684 10.5366,22.2934C10.5367,22.2933 10.5369,22.2931 10.5371,22.2929L16.41,16.41C16.78,16.05 17,15.55 17,15L17,5C17,3.89 16.1,3 15,3Z" />
		</SvgIcon>
	);
}