// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nrEZu_lC_xkjFe0HVANv {\n\theight: 100%;\n\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.fjwNgBHbnKkUlNFFengm {\n\tmargin: 16px;\n\tmax-width: 1000px;\n\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: flex-start;\n\tjustify-content: center;\n}\n\n.I8_eJBt3DV5qNPRcRmPI {\n\tdisplay: flex;\n\tflex-direction: column;\n\n\tmargin-inline-start: 16px;\n}\n\n.XqO3mjn55u4qgcboYPZo {\n\twidth: 64px !important;\n\theight: 64px !important;\n}\n\n.WKJrLZ4numIBLtTksZKx {\n\tdisplay: flex;\n\tflex-direction: row;\n\n\tmargin-top: 12px;\n}\n\n.SvN_Aw1zEUnpKaIKxj5h {\n\tflex-direction: row-reverse;\n}\n\n.WKJrLZ4numIBLtTksZKx > button:not(:first-child) {\n\tmargin-left: 16px;\n}", "",{"version":3,"sources":["webpack://./src/components/messaging/detail/DetailError.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;;CAEZ,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,iBAAiB;;CAEjB,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,sBAAsB;;CAEtB,yBAAyB;AAC1B;;AAEA;CACC,sBAAsB;CACtB,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,mBAAmB;;CAEnB,gBAAgB;AACjB;;AAEA;CACC,2BAA2B;AAC5B;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".container {\n\theight: 100%;\n\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.main {\n\tmargin: 16px;\n\tmax-width: 1000px;\n\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: flex-start;\n\tjustify-content: center;\n}\n\n.split {\n\tdisplay: flex;\n\tflex-direction: column;\n\n\tmargin-inline-start: 16px;\n}\n\n.icon {\n\twidth: 64px !important;\n\theight: 64px !important;\n}\n\n.buttonRow {\n\tdisplay: flex;\n\tflex-direction: row;\n\n\tmargin-top: 12px;\n}\n\n.buttonRowReverse {\n\tflex-direction: row-reverse;\n}\n\n.buttonRow > button:not(:first-child) {\n\tmargin-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "nrEZu_lC_xkjFe0HVANv",
	"main": "fjwNgBHbnKkUlNFFengm",
	"split": "I8_eJBt3DV5qNPRcRmPI",
	"icon": "XqO3mjn55u4qgcboYPZo",
	"buttonRow": "WKJrLZ4numIBLtTksZKx",
	"buttonRowReverse": "SvN_Aw1zEUnpKaIKxj5h"
};
export default ___CSS_LOADER_EXPORT___;
