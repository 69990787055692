import React from "react";

import {SvgIcon, SvgIconProps} from "@mui/material";

export default function TapbackLaughIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path d="M2,12C2,17.53 6.47,22 12,22C17.5228,22 22,17.5228 22,12C22,6.47 17.5,2 12,2C6.47,2 2,6.47 2,12ZM10.6464,10.2936L10.2936,10.6464C10.0983,10.8417 9.7817,10.8417 9.5864,10.6464L8.88,9.94L8.1736,10.6464C7.9783,10.8417 7.6617,10.8417 7.4664,10.6464L7.1136,10.2936C6.9183,10.0983 6.9183,9.7817 7.1136,9.5864L8.5264,8.1736C8.7217,7.9783 9.0383,7.9783 9.2336,8.1736L10.6464,9.5864C10.8417,9.7817 10.8417,10.0983 10.6464,10.2936ZM7.7132,14L16.2867,13.9999C16.5629,13.9999 16.7868,14.2238 16.7868,14.5C16.7868,14.5892 16.7629,14.6769 16.7176,14.7538C15.6394,16.5846 14.0669,17.5 12,17.5C9.9331,17.5 8.3605,16.5846 7.2823,14.7537L7.2824,14.7537C7.1423,14.5157 7.2215,14.2093 7.4595,14.0692C7.5363,14.0239 7.6239,14 7.7132,14ZM15.8264,10.6464L15.12,9.94L14.4136,10.6464C14.2183,10.8417 13.9017,10.8417 13.7064,10.6464L13.3536,10.2936C13.1583,10.0983 13.1583,9.7817 13.3536,9.5864L14.7664,8.1736C14.9617,7.9783 15.2783,7.9783 15.4736,8.1736L16.8864,9.5864C17.0817,9.7817 17.0817,10.0983 16.8864,10.2936L16.5336,10.6464C16.3383,10.8417 16.0217,10.8417 15.8264,10.6464Z" />
		</SvgIcon>
	);
}