// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yjys9A6id5F1H9YGDTst {\n\tdisplay: flex;\n\talign-items: center;\n\tfont-family: 'Manrope', sans-serif;\n\tfont-size: 22px;\n\tfont-weight: bold;\n\tcolor: red;\n}\n\n.yjys9A6id5F1H9YGDTst > img {\n\twidth: 22px;\n\theight: 22px;\n}\n\n.yjys9A6id5F1H9YGDTst > span {\n\tmargin-left: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/logo/AirMessageLogo.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,kCAAkC;CAClC,eAAe;CACf,iBAAiB;CACjB,UAAU;AACX;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".logo {\n\tdisplay: flex;\n\talign-items: center;\n\tfont-family: 'Manrope', sans-serif;\n\tfont-size: 22px;\n\tfont-weight: bold;\n\tcolor: red;\n}\n\n.logo > img {\n\twidth: 22px;\n\theight: 22px;\n}\n\n.logo > span {\n\tmargin-left: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "yjys9A6id5F1H9YGDTst"
};
export default ___CSS_LOADER_EXPORT___;
