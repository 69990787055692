import React from "react";

import {SvgIcon, SvgIconProps} from "@mui/material";

export default function TapbackLikeIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path d="M21,8L14.68,8L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.8771,1.7004C14.486,1.3131 13.8555,1.3145 13.4662,1.7038L7.59,7.58C7.22,7.95 7,8.45 7,9L7,19C7,20.1046 7.8954,21 9,21L18,21C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12L23,10C23,8.89 22.1,8 21,8ZM2,21L4,21C4.5523,21 5,20.5523 5,20L5,10C5,9.4477 4.5523,9 4,9L2,9C1.4477,9 1,9.4477 1,10L1,20C1,20.5523 1.4477,21 2,21Z" />
		</SvgIcon>
	);
}