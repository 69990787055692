import React from "react";

import {SvgIcon, SvgIconProps} from "@mui/material";

export default function PushIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path d="M12,22C9.3478,22 6.8043,20.9464 4.9289,19.0711C3.0536,17.1957 2,14.6522 2,12C2,9.3478 3.0536,6.8043 4.9289,4.9289C6.8043,3.0536 9.3478,2 12,2C14.6522,2 17.1957,3.0536 19.0711,4.9289C20.9464,6.8043 22,9.3478 22,12C22,17.5228 17.5228,22 12,22ZM13,17C13,16.6667 13,14.1667 13,9.5L15.7929,12.2929C16.1834,12.6834 16.8166,12.6834 17.2071,12.2929L17.21,12.29L17.2129,12.2871C17.6034,11.8966 17.6034,11.2634 17.2129,10.8729L12.7071,6.3671C12.3166,5.9766 11.6834,5.9766 11.2929,6.3671L6.7871,10.8729C6.3966,11.2634 6.3966,11.8966 6.7871,12.2871L6.7929,12.2929C7.1834,12.6834 7.8166,12.6834 8.2071,12.2929L11,9.5C11,14.1667 11,16.6667 11,17C11,17.5 11.5,18 12,18C12.5,18 13,17.5 13,17Z" />
		</SvgIcon>
	);
}