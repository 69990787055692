export const connectHostname = "wss://connect.airmessage.org";

export const googleApiKey = "AIzaSyDp4BJZXK0do8DbipwMRuB3wOKqQykaYfg";
export const googleClientID = "557377230163-kt5ffj5r02i0cutuqntp28m65fg662k0.apps.googleusercontent.com";
export const googleClientSecret = "Hr7zc5H2tI9RYjRaawd1oT6o";

export const firebaseConfig = {
	apiKey: "AIzaSyDp4BJZXK0do8DbipwMRuB3wOKqQykaYfg",
	authDomain: "airmessage-b2c68.firebaseapp.com",
	databaseURL: "https://airmessage-b2c68.firebaseio.com",
	projectId: "airmessage-b2c68",
	storageBucket: "airmessage-b2c68.appspot.com",
	messagingSenderId: "557377230163",
	appId: "1:557377230163:web:21334bcedb4f6800373da6",
	measurementId: "G-XD56L85QZD"
};

export const sentryDSN = "https://38dc0e905711458d9f5a96371cb93642@o136515.ingest.sentry.io/5438890";

export const jwkLocalEncryption: JsonWebKey = {
	kty: "oct",
	k:"CLkGgy2NnLdPPRVNS56FpQ",
	alg:"A128GCM"
};
