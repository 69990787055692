// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XxQPJKc5EMZ9XiygQijX {\n\tflex-grow: 1;\n\toverflow-x: hidden;\n\toverflow-y: scroll;\n}\n\n.PWHf3iosrA_ibCeJFlZE {\n\tflex-grow: 1;\n\toverflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/components/messaging/master/Sidebar.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,gBAAgB;AACjB","sourcesContent":[".sidebarList {\n\tflex-grow: 1;\n\toverflow-x: hidden;\n\toverflow-y: scroll;\n}\n\n.sidebarListLoading {\n\tflex-grow: 1;\n\toverflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarList": "XxQPJKc5EMZ9XiygQijX",
	"sidebarListLoading": "PWHf3iosrA_ibCeJFlZE"
};
export default ___CSS_LOADER_EXPORT___;
