import React from "react";

import {SvgIcon, SvgIconProps} from "@mui/material";

export default function TapbackQuestionIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<path d="M12,16L12,16C12.5523,16 13,16.4477 13,17L13,17C13,17.5523 12.5523,18 12,18L12,18C11.4477,18 11,17.5523 11,17L11,17C11,16.4477 11.4477,16 12,16ZM8.18,8.83C8,9.41 8.44,10 9.05,10L9.25,10C9.66,10 9.99,9.71 10.13,9.33C10.45,8.44 11.4,7.83 12.43,8.05C13.38,8.25 14.08,9.18 14,10.15C13.9,11.49 12.38,11.78 11.55,13.03C11.55,13.04 11.54,13.04 11.54,13.05C11.53,13.07 11.52,13.08 11.51,13.1C11.42,13.25 11.33,13.42 11.26,13.6C11.25,13.63 11.23,13.65 11.22,13.68C11.21,13.7 11.21,13.72 11.2,13.75C11.1829,13.7984 11.1665,13.8554 11.1506,13.9211L11.1506,13.9211C11.0374,14.3903 11.3258,14.8623 11.7949,14.9756C11.8621,14.9918 11.9309,15 12,15L12.1835,15C12.6295,15 13.0217,14.7045 13.1447,14.2757C13.1861,14.1314 13.2312,14.0162 13.28,13.93C13.3,13.9 13.31,13.87 13.33,13.84C13.41,13.7 13.51,13.57 13.61,13.45C13.62,13.44 13.63,13.42 13.64,13.41C13.74,13.29 13.85,13.18 13.97,13.07C14.93,12.16 16.23,11.42 15.96,9.51C15.72,7.77 14.35,6.3 12.61,6.04C10.55,5.74 8.73,7.01 8.18,8.83Z" />
		</SvgIcon>
	);
}